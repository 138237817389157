import "./WeaponsContainer.scss";
import { useState } from "react";
import { WeaponManager } from "../../../lib/appearance/weapon_manager";
import WeaponElement from "../../Element/WeaponElement/WeaponElement";
import { useDispatch } from "react-redux";
import {
  addWeapon,
  WeaponPosition,
  WeaponsSaveObject,
} from "../../../store/weapons";

type WeaponsContainerProps = {
  selectedWeapons?: WeaponsSaveObject;
};

const WeaponsContainer = ({ selectedWeapons }: WeaponsContainerProps) => {
  const dispatch = useDispatch();

  const weapons = WeaponManager.instance.getItems();

  const [filterText, setFilterText] = useState<string>("");

  const filterItems = (item: string): boolean => {
    return item.toLowerCase().includes(filterText.toLowerCase());
  };

  return (
    <>
      <div className="top-bar">
        <input
          className="filter-input"
          type="search"
          placeholder="Search"
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
        />
      </div>
      <div className="weapons-main-container">
        {(["left", "right"] as WeaponPosition[]).map(
          (position: WeaponPosition) => {
            return (
              <div key={position} style={{ flex: 1 }}>
                <span className="weapon-position-label">{position} Weapon</span>
                <div className="scroll-box">
                  <div className="weapons-side-container">
                    {weapons
                      .filter((weapon) => filterItems(weapon.name))
                      .map((weapon) => {
                        return (
                          <WeaponElement
                            weapon={weapon}
                            key={position + weapon.id}
                            selected={selectedWeapons?.[position] === weapon.id}
                            onClick={(id: string) => {
                              dispatch(addWeapon({ id, position }));
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default WeaponsContainer;
