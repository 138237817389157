import React from "react";
import { AudioManager } from "../../lib/audio/audioManager";
import "./InteractiveElement.scss";
import { SettingsState } from "../../store/settings";
import { useSelector } from "react-redux";
import { DLC_DATA, DLC_MAPPING } from "../../types/GameTypes";

const InteractiveElement = ({
  selected = true,
  onClick = () => {},
  imageStyle = {},
  dlcData = DLC_MAPPING.BaseGame,
  name = "",
  ...props
}: {
  selected?: boolean;
  onClick?: Function;
  imageStyle?: React.CSSProperties;
  children?: React.ReactNode | React.ReactNode[];
  dlcData?: DLC_DATA;
  name: string;
  [key: string]: any;
}) => {
  const { showLabels }: SettingsState = useSelector(
    (state: any) => state.settings
  );

  return (
    <div
      onClick={(event) => {
        onClick(event);
        AudioManager.click();
      }}
      className={
        "interactive-element-container " + (selected ? "selected" : "")
      }
      {...props}
    >
      <div className="interaction-element"></div>
      <div className="image-container" style={imageStyle}>
        {props.children}
      </div>
      {showLabels && (
        <span
          style={{
            fontWeight: "bold",
            marginTop: 35,
            color: dlcData.fontColor,
          }}
        >
          {name}
        </span>
      )}
    </div>
  );
};

export default InteractiveElement;
