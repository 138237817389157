import { useSelector } from "react-redux";
import "../InteractiveElement.scss";
import { ItemMode, SettingsState } from "../../../store/settings";
import { Appearance, Item } from "../../../lib/appearance/item_manager";
import { DLC_MAPPING } from "../../../types/GameTypes";
import InteractiveElement from "../InteractiveElement";

function ItemElement({
  item,
  selected = true,
  onClick = () => {},
  itemSettings = { imageMode: ItemMode.Item, showText: true },
  groupName = "",
  ...props
}: {
  item: Item;
  selected?: boolean;
  onClick?: Function;
  groupName?: string;
  [key: string]: any;
}) {
  const { itemMode }: SettingsState = useSelector(
    (state: any) => state.settings
  );

  const appearanceItem: Appearance[] = item.appearance;

  return (
    <InteractiveElement
      selected={selected}
      onClick={() => {
        onClick(item.id);
      }}
      imageStyle={{
        top: itemMode === ItemMode.Equiped ? 20 : 20,
      }}
      dlcData={DLC_MAPPING[item.dlc]}
      name={item.name}
      {...props}
    >
      {itemMode === ItemMode.Equiped && (
        <img
          className="base"
          key={item.name + "_base"}
          alt="base"
          src={`assets/${DLC_MAPPING[item.dlc].path}/base.png`}
          style={{
            opacity: 0.7,
          }}
        />
      )}
      {appearanceItem.map((appearanceItem: Appearance) => {
        return (
          <img
            key={appearanceItem.path}
            src={itemMode === ItemMode.Item ? item.icon : appearanceItem.path}
            alt={item.name}
            style={{
              width: itemMode === ItemMode.Item ? 50 : 100,
              height: itemMode === ItemMode.Item ? 50 : 100,
              position: "absolute",
              alignSelf: "center",
              zIndex: appearanceItem.depth,
            }}
          />
        );
      })}
    </InteractiveElement>
  );
}

export default ItemElement;
