import { WEAPON_OBJECT } from "../../../../types/AppearanceTypes";

const defaultDimension = {
  scale: 0.5,
  left: {
    top: 65,
    left: -110,
    rotation: 50,
  },
  right: {
    top: 65,
    left: 110,
    rotation: 310,
  },
};

export const weapons: WEAPON_OBJECT[] = [
  {
    id: "cacti_club",
    name: "Cacti Club",
    image: "assets/base_game/weapons/cacti_club.png",
    icon: "assets/base_game/weapons/cacti_club_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "chain_gun",
    name: "Chain Gun",
    image: "assets/base_game/weapons/chain_gun.png",
    icon: "assets/base_game/weapons/chain_gun_icon.png",
    dimensions: {
      scale: 0.5,
      left: {
        ...defaultDimension.left,
        left: -180,
      },
      right: {
        ...defaultDimension.right,
        left: 65,
      },
    },
  },
  {
    id: "chopper",
    name: "Chopper",
    image: "assets/base_game/weapons/chopper.png",
    icon: "assets/base_game/weapons/chopper_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "circular_saw",
    name: "Circular Saw",
    image: "assets/base_game/weapons/circular_saw.png",
    icon: "assets/base_game/weapons/circular_saw_icon.png",
    dimensions: {
      scale: 0.35,
      left: {
        ...defaultDimension.left,
        top: 20,
        left: -195,
        rotation: 0,
      },
      right: {
        ...defaultDimension.right,
        left: 80,
        top: 20,
        rotation: 0,
      },
    },
  },
  {
    id: "claw",
    name: "Claw",
    image: "assets/base_game/weapons/claw.png",
    icon: "assets/base_game/weapons/claw_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top + 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 60,
        top: defaultDimension.left.top + 20,
      },
    },
  },
  {
    id: "crossbow",
    name: "Crossbow",
    image: "assets/base_game/weapons/crossbow.png",
    icon: "assets/base_game/weapons/crossbow_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.15,
      left: {
        ...defaultDimension.left,
        left: -300,
        top: -100,
      },
      right: {
        ...defaultDimension.right,
        left: -60,
        top: -100,
      },
    },
  },
  {
    id: "dagger",
    name: "Dagger",
    image: "assets/base_game/weapons/dagger.png",
    icon: "assets/base_game/weapons/dagger_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "dextroyer",
    name: "Dextroyer",
    image: "assets/base_game/weapons/dextroyer.png",
    icon: "assets/base_game/weapons/dextroyer_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: -300,
      },
      right: {
        ...defaultDimension.right,
        left: -60,
      },
    },
  },
  {
    id: "double_barrel_shotgun",
    name: "Double Barrel Shotgun",
    image: "assets/base_game/weapons/double_barrel_shotgun.png",
    icon: "assets/base_game/weapons/double_barrel_shotgun_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.2,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 180,
        top: defaultDimension.left.top - 150,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 180,
        top: defaultDimension.right.top - 150,
      },
    },
  },
  {
    id: "drill",
    name: "Drill",
    image: "assets/base_game/weapons/drill.png",
    icon: "assets/base_game/weapons/drill_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.15,
      left: {
        ...defaultDimension.left,
        left: -300,
        top: -80,
      },
      right: {
        ...defaultDimension.right,
        left: -60,
        top: -80,
      },
    },
  },
  {
    id: "excalibur",
    name: "Excalibur",
    image: "assets/base_game/weapons/excalibur.png",
    icon: "assets/base_game/weapons/excalibur_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: -170,
        top: 75,
      },
      right: {
        ...defaultDimension.right,
        left: 60,
        top: 75,
      },
    },
  },
  {
    id: "fighting_stick",
    name: "Fighting Stick",
    image: "assets/base_game/weapons/fighting_stick.png",
    icon: "assets/base_game/weapons/fighting_stick_icon.png",
    dimensions: {
      scale: 0.4,
      left: {
        ...defaultDimension.left,
        left: -300,
      },
      right: {
        ...defaultDimension.right,
        left: -60,
      },
    },
  },
  {
    id: "fireball",
    name: "Fireball",
    image: "assets/base_game/weapons/fireball.png",
    icon: "assets/base_game/weapons/fireball_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "fist",
    name: "Fist",
    image: "assets/base_game/weapons/fist.png",
    icon: "assets/base_game/weapons/fist_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: -160,
      },
      right: {
        ...defaultDimension.right,
        left: 50,
      },
    },
  },
  {
    id: "flamethrower",
    name: "Flamethrower",
    image: "assets/base_game/weapons/flamethrower.png",
    icon: "assets/base_game/weapons/flamethrower_icon.png",
    dimensions: {
      scale: 0.35,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top - 120,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 60,
        top: defaultDimension.right.top - 120,
      },
    },
  },
  {
    id: "flaming_knuckles",
    name: "Flaming Knuckles",
    image: "assets/base_game/weapons/flaming_knuckles_icon.png",
    icon: "assets/base_game/weapons/flaming_knuckles_icon.png",
    dimensions: {
      scale: 0.5,
      left: {
        ...defaultDimension.left,
        left: -50,
        rotation: 0,
      },
      right: {
        ...defaultDimension.right,
        left: 200,
        rotation: 0,
      },
    },
  },
  {
    id: "ghost_axe",
    name: "Ghost Axe",
    image: "assets/base_game/weapons/ghost_axe.png",
    icon: "assets/base_game/weapons/ghost_axe_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
      },
    },
  },
  {
    id: "ghost_flint",
    name: "Ghost Flint",
    image: "assets/base_game/weapons/ghost_flint.png",
    icon: "assets/base_game/weapons/ghost_flint_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "ghost_scepter",
    name: "Ghost Scepter",
    image: "assets/base_game/weapons/ghost_scepter.png",
    icon: "assets/base_game/weapons/ghost_scepter_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "hammer",
    name: "Hammer",
    image: "assets/base_game/weapons/hammer.png",
    icon: "assets/base_game/weapons/hammer_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
      },
    },
  },
  {
    id: "hand",
    name: "Hand",
    image: "assets/base_game/weapons/hand.png",
    icon: "assets/base_game/weapons/hand_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        rotation: 0,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        rotation: 0,
      },
    },
  },
  {
    id: "hatchet",
    name: "Hatchet",
    image: "assets/base_game/weapons/hatchet.png",
    icon: "assets/base_game/weapons/hatchet_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "icicle",
    name: "Icicle",
    image: "assets/base_game/weapons/icicle.png",
    icon: "assets/base_game/weapons/icicle_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "jousting_lance",
    name: "Jousting Lance",
    image: "assets/base_game/weapons/jousting_lance.png",
    icon: "assets/base_game/weapons/jousting_lance_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 210,
        top: defaultDimension.left.top - 10,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 150,
        top: defaultDimension.right.top - 10,
      },
    },
  },
  {
    id: "knife",
    name: "Knife",
    image: "assets/base_game/weapons/knife.png",
    icon: "assets/base_game/weapons/knife_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "knuckles",
    name: "Knuckles",
    image: "assets/base_game/weapons/knuckles.png",
    icon: "assets/base_game/weapons/knuckles_icon.png",
    dimensions: {
      scale: 0.5,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top + 10,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 60,
        top: defaultDimension.right.top + 10,
      },
    },
  },
  {
    id: "laser_gatling",
    name: "Laser Gatling",
    image: "assets/base_game/weapons/laser_gatling.png",
    icon: "assets/base_game/weapons/laser_gatling_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top + 10,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.left.top + 10,
      },
    },
  },
  {
    id: "laser_pistol",
    name: "Laser Pistol",
    image: "assets/base_game/weapons/laser_pistol.png",
    icon: "assets/base_game/weapons/laser_pistol_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.15,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 40,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "lightning_shiv",
    name: "Lightning Shiv",
    image: "assets/base_game/weapons/lightning_shiv.png",
    icon: "assets/base_game/weapons/lightning_shiv_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "medical_gun",
    name: "Medical Gun",
    image: "assets/base_game/weapons/medical_gun.png",
    icon: "assets/base_game/weapons/medical_gun_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.15,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 40,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "minigun",
    name: "Minigun",
    image: "assets/base_game/weapons/minigun.png",
    icon: "assets/base_game/weapons/minigun_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 70,
        top: defaultDimension.left.top + 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 40,
        top: defaultDimension.left.top + 20,
      },
    },
  },
  {
    id: "nuclear_launcher",
    name: "Nuclear Launcher",
    image: "assets/base_game/weapons/nuclear_launcher.png",
    icon: "assets/base_game/weapons/nuclear_launcher_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top + 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 90,
        top: defaultDimension.left.top + 20,
      },
    },
  },
  {
    id: "obliterator",
    name: "Obliterator",
    image: "assets/base_game/weapons/obliterator.png",
    icon: "assets/base_game/weapons/obliterator_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top + 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top + 20,
      },
    },
  },
  {
    id: "particle_accelerator",
    name: "Particle Accelerator",
    image: "assets/base_game/weapons/particle_accelerator.png",
    icon: "assets/base_game/weapons/particle_accelerator_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top + 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top + 20,
      },
    },
  },
  {
    id: "pistol",
    name: "Pistol",
    image: "assets/base_game/weapons/pistol.png",
    icon: "assets/base_game/weapons/pistol_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.2,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 40,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "plank",
    name: "Plank",
    image: "assets/base_game/weapons/plank.png",
    icon: "assets/base_game/weapons/plank_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "plasma_sledgehammer",
    name: "Plasma Sledgehammer",
    image: "assets/base_game/weapons/plasma_sledgehammer.png",
    icon: "assets/base_game/weapons/plasma_sledgehammer_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top + 10,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 60,
        top: defaultDimension.right.top + 10,
      },
    },
  },
  {
    id: "potato_thrower",
    name: "Potato Thrower",
    image: "assets/base_game/weapons/potato_thrower.png",
    icon: "assets/base_game/weapons/potato_thrower_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top + 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top + 20,
      },
    },
  },
  {
    id: "power_fist",
    name: "Power Fist",
    image: "assets/base_game/weapons/power_fist.png",
    icon: "assets/base_game/weapons/power_fist_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 60,
        top: defaultDimension.right.top,
      },
    },
  },
  {
    id: "pruner",
    name: "Pruner",
    image: "assets/base_game/weapons/pruner.png",
    icon: "assets/base_game/weapons/pruner_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top - 120,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 60,
        top: defaultDimension.right.top - 120,
      },
    },
  },
  {
    id: "revolver",
    name: "Revolver",
    image: "assets/base_game/weapons/revolver.png",
    icon: "assets/base_game/weapons/revolver_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.2,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 80,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "rock",
    name: "Rock",
    image: "assets/base_game/weapons/rock.png",
    icon: "assets/base_game/weapons/rock_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top - 120,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.left.top - 120,
      },
    },
  },
  {
    id: "rocket_launcher",
    name: "Rocket Launcher",
    image: "assets/base_game/weapons/rocket_launcher.png",
    icon: "assets/base_game/weapons/rocket_launcher_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top + 10,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.right.top + 10,
      },
    },
  },
  {
    id: "scissors",
    name: "Scissors",
    image: "assets/base_game/weapons/scissors.png",
    icon: "assets/base_game/weapons/scissors_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top - 100,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.right.top - 100,
      },
    },
  },
  {
    id: "screwdriver",
    name: "Screwdriver",
    image: "assets/base_game/weapons/screwdriver.png",
    icon: "assets/base_game/weapons/screwdriver_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.15,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top - 110,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.right.top - 101,
      },
    },
  },
  {
    id: "scythe",
    name: "Scythe",
    image: "assets/base_game/weapons/scythe.png",
    icon: "assets/base_game/weapons/scythe_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top - 120,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.right.top - 120,
      },
    },
  },
  {
    id: "sharp_tooth",
    name: "Sharp Tooth",
    image: "assets/base_game/weapons/sharp_tooth.png",
    icon: "assets/base_game/weapons/sharp_tooth_icon.png",
    dimensions: defaultDimension,
  },
  {
    id: "shredder",
    name: "Shredder",
    image: "assets/base_game/weapons/shredder.png",
    icon: "assets/base_game/weapons/shredder_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.2,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 80,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "shuriken",
    name: "Shuriken",
    image: "assets/base_game/weapons/shuriken.png",
    icon: "assets/base_game/weapons/shuriken_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.15,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top - 100,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 70,
        top: defaultDimension.left.top - 100,
      },
    },
  },
  {
    id: "slingshot",
    name: "Slingshot",
    image: "assets/base_game/weapons/slingshot.png",
    icon: "assets/base_game/weapons/slingshot_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 20,
        top: defaultDimension.left.top - 130,
        rotation: 0,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 90,
        top: defaultDimension.left.top - 130,
        rotation: 0,
      },
    },
  },
  {
    id: "smg",
    name: "Smg",
    image: "assets/base_game/weapons/smg.png",
    icon: "assets/base_game/weapons/smg_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.2,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 80,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "sniper",
    name: "Sniper",
    image: "assets/base_game/weapons/sniper.png",
    icon: "assets/base_game/weapons/sniper_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 200,
        top: defaultDimension.left.top - 160,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 160,
        top: defaultDimension.left.top - 160,
      },
    },
  },
  {
    id: "spear",
    name: "Spear",
    image: "assets/base_game/weapons/spear.png",
    icon: "assets/base_game/weapons/spear_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 220,
        top: defaultDimension.left.top - 20,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 150,
        top: defaultDimension.right.top - 20,
      },
    },
  },
  {
    id: "spiky_shield",
    name: "Spiky Shield",
    image: "assets/base_game/weapons/spiky_shield.png",
    icon: "assets/base_game/weapons/spiky_shield_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left + 70,
        top: defaultDimension.left.top + 30,
        rotation: 0,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left + 70,
        top: defaultDimension.right.top + 30,
        rotation: 0,
      },
    },
  },
  {
    id: "stick",
    name: "Stick",
    image: "assets/base_game/weapons/stick.png",
    icon: "assets/base_game/weapons/stick_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 50,
        top: defaultDimension.left.top,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.right.top,
      },
    },
  },
  {
    id: "sword",
    name: "Sword",
    image: "assets/base_game/weapons/sword.png",
    icon: "assets/base_game/weapons/sword_icon.png",
    dimensions: {
      ...defaultDimension,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top + 10,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 40,
        top: defaultDimension.right.top + 10,
      },
    },
  },
  {
    id: "taser",
    name: "Taser",
    image: "assets/base_game/weapons/taser.png",
    icon: "assets/base_game/weapons/taser_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.2,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 30,
        top: defaultDimension.left.top - 130,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 80,
        top: defaultDimension.left.top - 130,
      },
    },
  },
  {
    id: "thunder_sword",
    name: "Thunder Sword",
    image: "assets/base_game/weapons/thunder_sword.png",
    icon: "assets/base_game/weapons/thunder_sword_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 80,
        top: defaultDimension.left.top,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 40,
        top: defaultDimension.left.top,
      },
    },
  },
  {
    id: "torch",
    name: "Torch",
    image: "assets/base_game/weapons/torch.png",
    icon: "assets/base_game/weapons/torch_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.left.top,
      },
    },
  },
  {
    id: "wand",
    name: "Wand",
    image: "assets/base_game/weapons/wand.png",
    icon: "assets/base_game/weapons/wand_icon.png",
    dimensions: {
      scale: defaultDimension.scale - 0.1,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.left.top,
      },
    },
  },
  {
    id: "wrench",
    name: "Wrench",
    image: "assets/base_game/weapons/wrench.png",
    icon: "assets/base_game/weapons/wrench_icon.png",
    dimensions: {
      scale: defaultDimension.scale,
      left: {
        ...defaultDimension.left,
        left: defaultDimension.left.left - 60,
        top: defaultDimension.left.top,
      },
      right: {
        ...defaultDimension.right,
        left: defaultDimension.right.left - 50,
        top: defaultDimension.left.top,
      },
    },
  },
];
