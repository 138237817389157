export const POSITIONS: string[] = [
  "other",
  "above head",
  "top left",
  "top right",
  "hats",
  "foreheads",
  "eyes",
  "noses",
  "mouths",
  "backs",
  "necks",
  "torsos",
  "pocket left",
  "pocket right",
  "skin",
  "tails",
  "accessory left",
  "accessory right",
];

export enum PRIORITY {
  VERY_LOW,
  LOW,
  MEDIUM,
  HIGH,
  VERY_HIGH,
}

export enum CATEGORY {
  ITEM,
  CHARACTER,
  COSMETIC,
  WEAPON,
}

export type APPEARANCE_OBJECT = {
  //hash: string;
  path: string;
  position: number;
  priority: number;
  depth: number;
  id?: string;
  name?: string;
};

export type ITEM_OBJECT = {
  id: string;
  name: string;
  icon: string;
  category: CATEGORY;
  beta?: boolean;
  appearance: APPEARANCE_OBJECT | APPEARANCE_OBJECT[];
};

export type WeaponDimension = {
  scale: number;
  left: {
    left: number;
    top: number;
    rotation: number;
  };
  right: {
    left: number;
    top: number;
    rotation: number;
  };
};

export type WEAPON_OBJECT = {
  id: string;
  name: string;
  image: string;
  icon: string;
  dimensions?: WeaponDimension;
};
