import { createSlice } from "@reduxjs/toolkit";

export type WeaponPosition = "left" | "right";

export type WeaponsSaveObject = { [key in WeaponPosition]: string | null };

export interface WeaponsState {
  weapons: WeaponsSaveObject;
  undo: WeaponsSaveObject[];
  redo: WeaponsSaveObject[];
}

const initialState = {
  weapons: {
    left: null,
    right: null,
  },
  undo: [],
  redo: [],
} satisfies WeaponsState as WeaponsState;

export const weaponsSlice = createSlice({
  name: "weapons",
  initialState,
  reducers: {
    undo: (state) => {
      const last = state.undo.pop();
      if (last) {
        state.redo.push(state.weapons);
        state.weapons = last;
      }
    },
    redo: (state) => {
      const last = state.redo.pop();
      if (last) {
        state.undo.push(state.weapons);
        state.weapons = last;
      }
    },
    add: (state, action) => {
      if (state.weapons.left || state.weapons.right)
        state.undo.push(state.weapons);
      state.redo = [];
      state.weapons = action.payload;
    },
    addWithoutSave: (state, action) => {
      state.weapons = action.payload;
    },
    addWeapon: (
      state,
      {
        payload,
      }: { payload: { id: string; position: WeaponPosition }; type: string }
    ) => {
      const { id, position } = payload as {
        id: string;
        position: WeaponPosition;
      };

      if (state.weapons[position] && state.weapons[position] === id) {
        state.weapons[position] = null;
      } else {
        state.weapons[position] = payload.id;
      }
    },
    clearActive: (state) => {
      if (state.weapons.left || state.weapons.right)
        state.undo.push(state.weapons);
      state.weapons = {
        left: null,
        right: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { undo, redo, add, addWithoutSave, addWeapon, clearActive } =
  weaponsSlice.actions;

export default weaponsSlice.reducer;
