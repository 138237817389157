import "./BannerElement.scss";
import { Banner } from "../../../store/settings";

import { useSelector } from "react-redux";

const BannerElement = () => {
  const { banner }: { banner: Banner } = useSelector(
    (state: any) => state.settings
  );

  return (
    <img
      src={`assets/banner/${banner}.png`}
      alt="box"
      className={`banner-${banner}`}
      id="banner"
    />
  );
};

export default BannerElement;
