export enum DLC {
  BaseGame = "BaseGame",
  AbyssalTerrors = "AbyssalTerrors",
}

export type DLC_DATA = {
  id: DLC;
  path: string;
  fontColor: string;
  color: string;
  name: string;
};

export const DLC_MAPPING: {
  [key in DLC]: DLC_DATA;
} = {
  BaseGame: {
    id: DLC.BaseGame,
    path: "base_game",
    fontColor: "black",
    color: "black",
    name: "Base Game",
  },
  AbyssalTerrors: {
    id: DLC.AbyssalTerrors,
    name: "Abyssal Terrors",
    path: "abyssal_terrors",
    fontColor: "#6e2c99",
    color: "#6e2c99",
  },
};
