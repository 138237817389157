import "./SettingsSection.scss";

function SettingsSection(props: {
  children?: React.ReactNode[] | React.ReactNode;
  title: string;
}) {
  return (
    <div className="settings-section">
      <span className="title">{props.title}</span>
      <div className="section-content">{props.children}</div>
    </div>
  );
}

export default SettingsSection;
