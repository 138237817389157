import "./WeaponsArea.scss";
import { WeaponsSaveObject } from "../../../store/weapons";
import { Weapon, WeaponManager } from "../../../lib/appearance/weapon_manager";

const WeaponsArea = ({ weapons }: { weapons: WeaponsSaveObject }) => {
  const weaponsManager = WeaponManager.instance;

  const leftWeapon: Weapon | undefined = weaponsManager.getItemById(
    weapons.left ?? ""
  );
  const rightWeapon: Weapon | undefined = weaponsManager.getItemById(
    weapons.right ?? ""
  );

  return (
    <div className="weapons-area">
      {leftWeapon && (
        <img
          src={leftWeapon?.apperance}
          alt="box"
          className="weapon-apperance weapon-left"
          style={{
            left: leftWeapon.dimensions?.left.left ?? 0,
            top: leftWeapon.dimensions?.left.top ?? 0,
            transform: `rotate(${
              leftWeapon.dimensions?.left.rotation ?? 0
            }deg) scale(-${leftWeapon.dimensions?.scale ?? 1}, ${
              leftWeapon.dimensions?.scale ?? 1
            })`,
          }}
        />
      )}
      {rightWeapon && (
        <img
          src={rightWeapon.apperance}
          alt="box"
          className="weapon-apperance weapon-right"
          style={{
            left: rightWeapon.dimensions?.right.left ?? 0,
            top: rightWeapon.dimensions?.right.top ?? 0,
            transform: `rotate(${
              rightWeapon.dimensions?.right.rotation ?? 0
            }deg) scale(${rightWeapon.dimensions?.scale ?? 1})`,
          }}
        />
      )}
    </div>
  );
};

export default WeaponsArea;
