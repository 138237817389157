import "./SocialContainer.scss";
import Coindrop from "./Coindrop/Coindrop";
import { ReactComponent as Discord } from "../../icons/discord.svg";

const SocialContainer = () => {
  return (
    <div id="social-container">
      <Discord
        id="discord"
        onClick={() => {
          //open link
          window.open("https://discord.gg/q43VqaTKPq", "_blank");
        }}
      />
      <Coindrop />
    </div>
  );
};

export default SocialContainer;
