import { useDispatch, useSelector } from "react-redux";
import "./Settings.scss";
import {
  setShowAnimation,
  setItemMode,
  setShowLabels,
  setShowLegs,
  SettingsState,
  setAudio,
  // setShowWeapons,
  setShowShadow,
  ItemMode,
  setDLCFilter,
  changeDocking,
  Banner,
  changeBanner,
} from "../../store/settings";
import ToggleButton from "../ToggleButton/ToggleButton";
import SettingsSection from "./Section/SettingsSection";

import { ReactComponent as ItemIcon } from "../../icons/item.svg";
import { ReactComponent as ShadowIcon } from "../../icons/shadow.svg";
import { ReactComponent as SoundIcon } from "../../icons/audio.svg";
import { ReactComponent as AnimationIcon } from "../../icons/animation.svg";
import { ReactComponent as LabelIcon } from "../../icons/label.svg";
import { ReactComponent as LegIcon } from "../../icons/leg.svg";
import { ReactComponent as DockingLeftIcon } from "../../icons/docking_left.svg";
import { ReactComponent as DockingBottomIcon } from "../../icons/docking_bottom.svg";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { DLC, DLC_MAPPING } from "../../types/GameTypes";

function Settings() {
  const settingsStore: SettingsState = useSelector(
    (state: any) => state.settings
  );
  const dispatch = useDispatch();

  const settings: {
    type: string;
    id: string;
    icon?: JSX.Element;
    checked: boolean;
    onValueChange: Function;
    label: string;
    beta?: boolean;
  }[] = [
    {
      type: "checkbox",
      id: "show-text",
      icon: <LabelIcon />,
      checked: settingsStore.showLabels,
      onValueChange: (e: boolean) => {
        dispatch(setShowLabels(e));
      },
      label: "Show Labels",
    },
    {
      type: "checkbox",
      id: "hide-legs-text",
      checked: settingsStore.showLegs,
      onValueChange: (e: boolean) => {
        dispatch(setShowLegs(e));
      },
      icon: <LegIcon />,
      label: "Show Legs",
    },
    {
      type: "checkbox",
      id: "show-animation",
      icon: <AnimationIcon />,
      checked: settingsStore.showAnimation,
      onValueChange: (e: boolean) => {
        dispatch(setShowAnimation(e));
      },
      label: "Show Animation",
    },
    {
      type: "checkbox",
      id: "sound-on",
      icon: <SoundIcon />,
      checked: settingsStore.audio,
      onValueChange: (e: boolean) => {
        dispatch(setAudio(e));
      },
      label: "Play Sound",
    },
    // {
    //   type: "checkbox",
    //   id: "show-weapons",
    //   checked: settingsStore.showWeapons,
    //   onValueChange: (e: boolean) => {
    //     dispatch(setShowWeapons(e));
    //   },
    //   label: "Show Weapons",
    //   beta: true,
    // },
    {
      type: "checkbox",
      id: "show-shadow",
      icon: <ShadowIcon />,
      checked: settingsStore.showShadow,
      onValueChange: (e: boolean) => {
        dispatch(setShowShadow(e));
      },
      label: "Show Shadow",
    },
  ];

  const createStyleForDLC = () => {
    const style = {
      "--abyssal-terrors-bg-color": "#6e2c99",
    } as React.CSSProperties;
    return style;
  };

  return (
    <div className="settings-container">
      <SettingsSection title="Options">
        {settings.map((setting, index) => (
          <ToggleButton
            key={index}
            icon={setting.icon}
            value={setting.checked}
            onValueChange={setting.onValueChange}
            label={setting.label + (setting.beta ? " (Beta)" : "")}
            style={setting.beta ? { backgroundColor: "orange" } : {}}
          >
            {setting.icon}
          </ToggleButton>
        ))}
      </SettingsSection>
      <SettingsSection title="Display">
        <ButtonGroup
          values={Object.values(ItemMode)}
          selected={settingsStore.itemMode}
          onRender={(key: string) => {
            return (
              <>
                <span>{key[0].toUpperCase() + key.slice(1)}</span>
                <ItemIcon />
              </>
            );
          }}
          onClick={(key: string) => dispatch(setItemMode(key))}
        />
        <ButtonGroup
          id="docking-mode"
          values={["bottom", "left"]}
          selected={settingsStore.docking}
          onChange={(key: string) => {
            dispatch(changeDocking(key));
          }}
          onRender={(key: string) => {
            return (
              <>
                <span>{key[0].toUpperCase() + key.slice(1)}</span>
                {key === "left" ? <DockingLeftIcon /> : <DockingBottomIcon />}
              </>
            );
          }}
        />
      </SettingsSection>

      <SettingsSection title="Items-Filter">
        <ButtonGroup
          id="dlc-filter"
          values={Object.values(DLC)}
          selected={settingsStore.dlcFilter}
          multiple={true}
          style={createStyleForDLC()}
          onChange={(key: DLC[]) => {
            dispatch(setDLCFilter(key));
          }}
          onRender={(key: DLC) => {
            return (
              <div
                style={{
                  padding: 2,
                }}
              >
                <img
                  src={`assets/${DLC_MAPPING[key].path}/logo.png`}
                  alt={`logo ${DLC_MAPPING[key].name}`}
                />
              </div>
            );
          }}
        />
      </SettingsSection>

      <SettingsSection title="Banner">
        <ButtonGroup
          id="banner-selection"
          values={Object.values(Banner).map((key: any) => {
            return key;
          })}
          selected={settingsStore.banner}
          onChange={(key: Banner) => {
            dispatch(changeBanner(key));
          }}
          onRender={(key: Banner) => {
            if (key === Banner.None) {
              return <span>None</span>;
            }

            return (
              <div
                style={{
                  padding: 2,
                }}
              >
                <img src={`assets/banner/${key}.png`} alt={`logo ${key}`} />
              </div>
            );
          }}
        />
      </SettingsSection>
    </div>
  );
}

export default Settings;
