import { useEffect, useState } from "react";
import "./AnalyticsToast.scss";
import { StorageManager } from "../../../lib/storage/storage_manager";

const GTag = "G-331XSLMX5X";

const AnalyticsToast = () => {
  const [analyticsToastVisible, setAnalyticsToastVisible] = useState<Boolean>(
    !StorageManager.has("consentGranted")
  );

  useEffect(() => {
    if (!analyticsToastVisible) {
      updateAnalyticsPermission(StorageManager.get("consentGranted"));
    }
  }, [analyticsToastVisible]);

  const updateAnalyticsPermission = (granted: Boolean = false) => {
    StorageManager.set("consentGranted", granted);
    setAnalyticsToastVisible(false);

    function gtag(...args: any) {
      if (window && (window as any).dataLayer)
        (window as any).dataLayer.push(arguments);
    }

    const permissionValue = granted ? "granted" : "denied";

    gtag("consent", "update", {
      ad_user_data: permissionValue,
      ad_personalization: permissionValue,
      ad_storage: permissionValue,
      analytics_storage: permissionValue,
    });

    // Load gtag.js script.
    var gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GTag}`;

    var firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode?.insertBefore(gtagScript, firstScript);
  };

  return (
    <>
      {analyticsToastVisible && (
        <div
          id="analytics"
          className="toast-notification"
          style={{
            opacity: 1,
            backgroundColor: "gray",
          }}
        >
          This page is using google analytics (I just want to know the traffic)!
          <div
            className="toast-button"
            onClick={() => {
              updateAnalyticsPermission();
            }}
          >
            Got it
          </div>
          <div
            className="toast-button nope"
            onClick={() => {
              updateAnalyticsPermission(false);
            }}
          >
            Nope
          </div>
        </div>
      )}
    </>
  );
};

export default AnalyticsToast;
