import {
  CATEGORY,
  WEAPON_OBJECT,
  WeaponDimension,
} from "../../types/AppearanceTypes";
import { DLC } from "../../types/GameTypes";
import { weapons } from "./data";

export class Weapon {
  public readonly id: string;
  public readonly name: string;
  public readonly icon: string;
  public readonly apperance: string;
  public readonly dimensions: WeaponDimension | undefined;
  public readonly dlc: DLC;
  public readonly category: CATEGORY = CATEGORY.WEAPON;

  constructor(weapon: WEAPON_OBJECT, dlc: DLC = DLC.BaseGame) {
    this.id = weapon.id;
    this.name = weapon.name;
    this.icon = weapon.icon;
    this.dimensions = weapon.dimensions;
    this.apperance = weapon.image;

    this.dlc = dlc;
  }
}

export class WeaponManager {
  private static _instance: WeaponManager;
  public items: Weapon[];

  private constructor() {
    this.items = this.getItems([DLC.BaseGame, DLC.AbyssalTerrors]);
  }

  public setItems = (filters: DLC[] = [DLC.BaseGame]): void => {
    this.items = this.getItems(filters);
  };

  public getItems = (filters: DLC[] = [DLC.BaseGame]): Weapon[] => {
    const dlcs = Object.values(DLC).filter((dlc) => filters.includes(dlc));
    if (dlcs.length === 0) {
      dlcs.push(DLC.BaseGame);
    }

    return dlcs
      .map((dlc: DLC) => {
        return Object.values(weapons[dlc]).map(
          (item: WEAPON_OBJECT) => new Weapon(item, dlc)
        );
      })
      .flat();
  };

  public static get instance(): WeaponManager {
    if (!this._instance) {
      this._instance = new WeaponManager();
    }
    return this._instance;
  }

  public getItemById(id: string): Weapon | undefined {
    return this.items.find((item) => item.id === id);
  }
}
