import "../InteractiveElement.scss";
import { DLC_MAPPING } from "../../../types/GameTypes";
import { Weapon } from "../../../lib/appearance/weapon_manager";
import InteractiveElement from "../InteractiveElement";

function WeaponElement({
  weapon,
  selected = false,
  onClick = () => {},
  groupName = "",
  ...props
}: {
  weapon: Weapon;
  selected?: boolean;
  onClick?: Function;
  groupName?: string;
  [key: string]: any;
}) {
  return (
    <InteractiveElement
      selected={selected}
      onClick={(evt: MouseEvent) => {
        onClick(weapon.id);
      }}
      imageStyle={{
        top: 20,
      }}
      dlcData={DLC_MAPPING[weapon.dlc]}
      name={weapon.name}
      {...props}
    >
      <img
        key={weapon.icon}
        src={weapon.icon}
        alt={weapon.name}
        style={{
          width: 50,
          height: 50,
          position: "absolute",
          alignSelf: "center",
          zIndex: 88888888888,
        }}
      />
    </InteractiveElement>
  );
}

export default WeaponElement;
