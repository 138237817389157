import "./Coindrop.scss";

const Coindrop = () => {
  return (
    <a
      id="coindrop-container"
      href="https://coindrop.to/brotatoavatar"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://raw.githubusercontent.com/remjx/coindrop/refs/heads/master/public/logo/piggy-64.png"
        alt="Coindrop.to me"
      />
    </a>
  );
};

export default Coindrop;
